import React from 'react';

import './exilium-element.scss';

interface IProps {
  rarity: string;
}

export const GFLRarity: React.FC<IProps> = ({ rarity }) => {
  return <div className={`gfl-rarity rar-${rarity}`}>{rarity}</div>;
};

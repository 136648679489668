import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { CustomPopover } from '../../cs/common/components/custom-popover';
import { GFLCharacterPopover } from './exilium-character-popover';
import { IGFLCharacter } from '../../common/model/graphql-types';

interface IProps {
  employee: IGFLCharacter;
  enablePopover?: boolean;
  showLabel?: boolean;
}

export const GFLCharacterCard: React.FC<IProps> = ({
  employee,
  showLabel,
  enablePopover
}) => {
  const image = employee.cardImage
    ? getImage(employee.cardImage.localFile.childImageSharp)
    : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="character-hover-box gfl"
      popover={<GFLCharacterPopover character={employee} />}
    >
      <Link to={'/gfl-exilium/characters/' + employee.slug}>
        <div
          className={`avatar card gfl rarity-${employee.rarity} ${
            showLabel ? showLabel : ''
          }`}
        >
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${employee.name}`}
            />
          )}
          <span className="floating-element zzz">
            {employee.class === 'Bulwark' && (
              <StaticImage
                src="../../../images/exilium/icons/class_bulwark.png"
                alt={employee.class}
              />
            )}
            {employee.class === 'Sentinel' && (
              <StaticImage
                src="../../../images/exilium/icons/class_sentinel.png"
                alt={employee.class}
              />
            )}
            {employee.class === 'Support' && (
              <StaticImage
                src="../../../images/exilium/icons/class_support.png"
                alt={employee.class}
              />
            )}
            {employee.class === 'Vanguard' && (
              <StaticImage
                src="../../../images/exilium/icons/class_vanguard.png"
                alt={employee.class}
              />
            )}
          </span>
          {showLabel && <span className="emp-name">{employee.name}</span>}
          {showLabel && employee.isNew && <span className="tag new">New</span>}
          {showLabel && employee.cnOnly && (
            <span className="tag future">CN</span>
          )}
        </div>
      </Link>
    </CustomPopover>
  );
};
